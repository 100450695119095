import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDevice } from '../hooks/useResponsive';
import { colors } from '../theme/colors';

const Success = () => {
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const CustomButton = styled(Button)({
    background: colors.primary,
    color: 'white',
    borderRadius: '6px',
    boxShadow: 'none',
    fontSize: '23px',
    fontWeight: '900',
    '&:hover': {
      background: colors.primary,
      color: 'white',
    },
  });
  const setReturn = () => {
    navigate('/home');
  };
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '4rem',
          width: '100%',
          height: '70vh',
        }}
      >
        <Typography
          sx={{
            marginTop: '1.5rem',
            textAlign: 'center',
            marginBottom: '1rem',
            color: colors.primary,
            fontSize: '36px',
            // fontSize: { xs: '26px', sm: '36px' },
            fontWeight: 'bolder',
          }}
        >
          Thank you for your Enquiry!
        </Typography>
        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              paddingBottom: '30px',
              fontWeight: '400',
              fontSize: ' 17px',
              lineHeight: 'normal',
              color: '#000',
              width: isMobile ? '313px' : '725px',
              margin: 'auto',
            }}
          >
            Our ŹExperts will visit your location at the scheduled time to bring your vision to life. We’re excited to
            help you find the perfect curtains to enhance your space.{' '}
          </Typography>
        </Box>

        <CustomButton variant="contained" className="request-btn" onClick={setReturn}>
          Explore More
        </CustomButton>
      </Box>
    </Container>
  );
};

export default Success;
