import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import { LoadScriptNext } from '@react-google-maps/api';
// import { GoogleMap, withGoogleMap } from 'react-google-maps';
import { colors } from '../theme/colors';
import { useDevice } from '../hooks/useResponsive';
import { createAppointment, getAllSlot } from '../server/api/slot';
import MyGoogleMap from '../components/Gmap/Gmap/googleMap';

export default function BookingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slotList, loading } = useSelector((state) => ({
    slotList: state.slot.slotList,
    loading: state.slot.loading,
  }));

  const [selectedDate, setSelectedDate] = useState(moment());
  const [markers, setMarkers] = useState();

  console.log(markers?.[0]);

  const [sessionValue, setSessionValue] = useState('');

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        ...value,
        session: sessionValue,

        date: moment(selectedDate).format('yyyy-MM-DD'),
        location: [markers?.[0]?.lng, markers?.[0]?.lat],
      },
      dispatch,
      navigate,
    };
    dispatch(createAppointment(credentials));
  };
  const {
    register,
    handleSubmit,
    // formState: { errors },
    // reset,
  } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      location: '',
    },
  });

  const CustomButton = styled(Button)({
    background: colors.primary,
    color: 'white',
    borderRadius: '0',
    boxShadow: 'none',
    fontSize: '23px',
    fontWeight: '900',
    '&:hover': {
      background: colors.primary,
      color: 'white',
    },
  });

  const { isMobile } = useDevice();

  // calender functions
  const today = moment(selectedDate).format('yyyy-MM-DD');
  const oneWeek = moment().add(6, 'days');

  const dayName = moment(selectedDate).format('dddd');
  const currentDate = moment(selectedDate).format('MMMM D');
  const currentYear = moment(selectedDate).format('yyyy');

  const filteredSessions = slotList?.response?.filter((item) => item?.date === today);

  useEffect(() => {
    dispatch(getAllSlot({ dispatch }));
  }, [dispatch, selectedDate]);

  return (
    <>
      <Container maxWidth="lg">
        {isMobile && (
          <Box display={'flex'} justifyContent={'center'} marginTop={'49px'}>
            <Typography variant="h5" sx={{ textAlign: 'center', color: `${colors.primary}` }} className="boldfont">
              Choose A Time Slot ↓
            </Typography>
          </Box>
        )}
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={isMobile ? 3 : 5}
        >
          <Box>
            <Stack
              sx={{
                border: `2px solid ${colors.primary}`,
                borderRadius: '23px',
                width: `${isMobile ? '338px' : '554px'}`,
                height: `${isMobile ? '318px' : '521px'}`,
              }}
              className="my-DateCalendar"
            >
              <LocalizationProvider dateAdapter={AdapterMoment} label={'"day"'}>
                <DateCalendar
                  sx={{
                    width: '100%',
                    height: '100vh',
                  }}
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  disablePast
                  maxDate={oneWeek}
                  views={['day']}
                />
              </LocalizationProvider>
            </Stack>
          </Box>

          {selectedDate && filteredSessions?.[0] && (
            <Box mt={isMobile && 4}>
              {loading ? (
                <Stack className="slotLoading" sx={{ marginRight: '150px' }}>
                  <CircularProgress color="inherit" size={'50px'} />
                </Stack>
              ) : (
                <Stack direction="column" spacing={1} mb={4}>
                  <Typography pb={1} variant="h4" color={'GrayText'} display={'flex'} justifyContent={'center'}>
                    {dayName}, {currentDate}, {currentYear}
                  </Typography>
                  {filteredSessions?.[0] &&
                  filteredSessions?.[0]?.availableSessions &&
                  !filteredSessions?.[0]?.isHoliday ? (
                    filteredSessions?.[0]?.availableSessions?.map((item, key) => (
                      <Button
                        key={key}
                        variant="contained"
                        sx={{
                          boxShadow: 'none',
                          borderRadius: 0,
                          backgroundColor: item?.session === sessionValue ? colors.primary : colors.secondary,
                          color: item?.session === sessionValue ? 'white' : colors.primary,
                          width: '338px',
                          height: '51px',
                          fontSize: '21PX',
                          border: `2px solid ${colors.primary}`,
                          '&:hover': {
                            backgroundColor: colors.primary,
                            color: 'white',
                          },
                          // justifyContent:"space-between",
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                        onClick={() => {
                          if (item?.availableAppoinmentCount > 0) {
                            setSessionValue(item?.session);
                            document.getElementById('scroll')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                          }
                        }}
                        disabled={item?.availableAppoinmentCount <= 0}
                      >
                        {/* Time Range */}
                        <span>
                          {item?.timeFrom} to&nbsp;{item?.timeTo}
                          {/* {moment(item?.timeFrom, 'HH:mm').startOf('hour').format('h:mm A')} to&nbsp;
                          {moment(item?.timeTo, 'HH:mm').startOf('hour').format('h:mm A')} */}
                        </span>

                        {/* Booked Badge (appears if availableAppoinmentCount is 0) */}
                        {item?.availableAppoinmentCount <= 0 && (
                          <span
                            style={{
                              position: 'absolute',
                              top: '6px',
                              right: '-17px',
                              backgroundColor: '#9a9a9a',
                              color: 'white',
                              padding: '5px 5rem',
                              borderRadius: '12px',
                              fontSize: '8px',
                              fontWeight: '800',
                              transform: 'rotate(47deg)',
                              transformOrigin: 'center center',
                              marginRight: '-4rem',
                              letterSpacing: '1px',
                            }}
                          >
                            Booked
                          </span>
                        )}
                      </Button>
                    ))
                  ) : (
                    <Typography sx={{ textAlign: 'center' }}>NO AVAILABLE SESSIONS</Typography>
                  )}
                </Stack>
              )}
            </Box>
          )}
        </Stack>
      </Container>
      <Box id="scroll" mt={10} mb={isMobile ? '79px' : '100px'}>
        <Container maxWidth="md">
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Grid container alignItems="center" spacing={1} mb={3}>
              <Grid item xs={12} md={6} mb={isMobile && '13px'}>
                <InputLabel sx={{ paddingBottom: '12px' }}>Name*</InputLabel>
                <TextField
                  id="name"
                  placeholder="Your Name"
                  required
                  fullWidth
                  type="text"
                  className="custom-textfield-booking "
                  name="name"
                  {...register('name', {
                    required: { value: true, message: 'Name is required' },
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel sx={{ paddingBottom: '12px' }}>Phone Number*</InputLabel>
                <TextField
                  id="phone"
                  placeholder="Enter Phone Number"
                  required
                  fullWidth
                  type="number"
                  className="custom-textfield-booking "
                  name="phone"
                  {...register('phone', {
                    required: { value: true, message: 'Phone Number is required' },
                    maxLength: 15,
                    minLength: 9,
                  })}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1} mb={3}>
              <Grid item xs={12} md={12} mb={isMobile && '13px'}>
                <InputLabel sx={{ paddingBottom: '12px' }}>Email*</InputLabel>
                <TextField
                  id="email"
                  placeholder="Enter Email"
                  fullWidth
                  type="email"
                  className="custom-textfield-booking "
                  name="email"
                  {...register('email', {
                    required: { value: true, message: 'Email is required' },
                  })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mb={3}>
              <InputLabel sx={{ paddingBottom: '12px' }}>Address*</InputLabel>
              <TextField
                id="address"
                placeholder="Enter Your Address"
                fullWidth
                multiline
                type="text"
                className="custom-textfield-booking "
                rows={4}
                name="address"
                {...register('address', {
                  required: { value: true, message: 'Address is required' },
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ paddingBottom: '12px' }}>how many windows do you have?</InputLabel>
              <TextField
                id="remarks"
                placeholder="Type here.."
                fullWidth
                multiline
                type="text"
                className="custom-textfield-booking "
                rows={4}
                name="remarks"
                {...register('remarks', {
                  required: { value: false, message: 'Remark is required' },
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ pt: 4, pb: 2 }}>Select your location (Optional)</InputLabel>
              <div>
                <MyGoogleMap
                  MAP_PUBLIC_KEY={'AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8'}
                  markers={markers}
                  setMarkers={setMarkers}
                />
              </div>
            </Grid>

            <Grid item xs={12} mt={isMobile ? 4 : 3}>
              <CustomButton type="submit" variant="contained" className="request-btn" fullWidth>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Footer/bigicon.png`}
                  alt="icon"
                  style={{ marginRight: '10px', height: '25px' }}
                />
                Book A Free Home Visit
                {loading && <CircularProgress color="inherit" sx={{ color: 'white', mx: 2 }} size={'18px'} />}
              </CustomButton>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
}
