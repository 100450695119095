import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from '../../../hooks/useResponsive';
import { colors } from '../../../theme/colors';
import { createEnquiry } from '../../../server/api/enquiry';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const CustomButton = styled(Button)({
  background: colors.primary,
  color: colors.secondary,
  borderRadius: '0',
  boxShadow: 'none',
  fontSize: '23px',
  fontWeight: '900',
  '&:hover': {
    background: '#be302f',
    color: 'white',
  },
});

Feature.propTypes = {
  hideSections: PropTypes.any,
};

export default function Feature() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const resetForm = () => {
    reset();
  };

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: value,
      dispatch,
      resetForm,
      navigate,
    };

    dispatch(createEnquiry(credentials));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
  });

  return (
    <div>
      <StyledRoot sx={{ marginBottom: isMobile ? '2rem' : 0 }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: { xs: '0', sm: '45px' },
            paddingBottom: { xs: '0', sm: '125px' },
            paddingLeft: { xs: '0', sm: '16px' },
            paddingRight: { xs: '0', sm: '16px' },
          }}
        >
          <Grid container direction="row" sx={{ backgroundColor: colors.secondary }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ padding: { xs: '1.5rem', sm: '5rem' }, marginTop: 'auto', marginBottom: 'auto' }}
            >
              <Typography
                gutterBottom
                sx={{
                  color: colors.primary,
                  fontWeight: '900',
                  fontSize: { xs: '30px', sm: '48px' },
                  lineHeight: 'normal',
                  fontStyle: 'normal',
                }}
              >
                Anything to discuss before Schedule?
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: '400',
                  color: 'black',
                  fontSize: { xs: '15px', sm: '17px' },
                  lineHeight: '25px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                One of our experts will get back to you within the day to discuss your home design and schedule our FREE
                Home visit.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={12}
              lg={6}
              sx={{
                backgroundColor: colors.secondary,
                alignItems: 'center',
                display: 'flex',
              }}
              pt={isMobile ? 2 : 5}
              pb={isMobile ? 8 : 5}
              px={isMobile ? 3 : 8}
            >
              <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      placeholder="Your Name"
                      fullWidth
                      {...register('name', {
                        required: 'Name is required',
                      })}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Phone Number</InputLabel>
                    <TextField
                      placeholder="Enter Phone Number"
                      type='number'
                      fullWidth
                      {...register('phone', {
                        required: 'Phone number is required',
                        minLength: {
                          value: 9,
                          message: 'Phone number must be at least 9 digits long',
                        },
                      })}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      placeholder="Your Email"
                      fullWidth
                      {...register('email', {
                        required: 'Email is required',
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Message</InputLabel>
                    <TextField
                      placeholder="Ask Anything..."
                      
                      fullWidth
                      multiline
                      rows={4}
                      {...register('message', {
                        required: 'Message is required',
                      })}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton type="submit" fullWidth>
                      Request a Callback!
                    </CustomButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
    </div>
  );
}
