import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Grid, Box, Typography, ListItem, List, ListItemText } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from '@mui/material/IconButton';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlaceIcon from '@mui/icons-material/Place';
import { Link, useLocation } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
import { colors } from '../../theme/colors';

const Footer = () => {
  const location = useLocation();
  const { isMobile } = useDevice();

  const [scrollValue, setScrollValue] = useState();

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollValue = window.scrollY;
      setScrollValue(scrollValue);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openMap = () => {
    window.open('https://maps.app.goo.gl/WR7YGzpGV4xfZ8nG6?g_st=com.google.maps.preview.copy');
  };

  const footerItems = [
    {
      itemName: 'Home',
      link: '/home',
    },
    {
      itemName: 'Products',
      link: '/products',
    },

    {
      itemName: 'Estimate',
      link: '/estimate',
    },
    {
      itemName: 'About',
      link: '/about',
    },
  ];

  const footerItemsMobile = [
    {
      itemName: 'Home',
      pathName: '/home',
      activeImage: '/assets/images/Footer/Orrange_home.png',
      inActiveImage: '/assets/images/Footer/Grey_home.png',
      alt: '1',
    },
    {
      itemName: 'Products',
      pathName: '/products',
      activeImage: '/assets/images/Footer/Orrange_product.png',
      inActiveImage: '/assets/images/Footer/Grey_product.png',
      alt: '2',
    },
    {
      itemName: 'Estimate',
      pathName: '/estimate',
      activeImage: '/assets/images/Footer/Orrange_estimate.png',
      inActiveImage: '/assets/images/Footer/Grey_estimate.png',
      alt: '3',
    },
    {
      itemName: 'About',
      pathName: '/about',
      activeImage: '/assets/images/Footer/Orrange_about.png',
      inActiveImage: '/assets/images/Footer/Grey_about.png',
      alt: '4',
    },
  ];

  return (
    <>
      <Grid
        sx={{
          backgroundColor: colors.secondary,
          p: { lg: 5, xs: 7 },
          '@media (max-width: 899px)': {
            p: '5rem 0 1rem 0 ',
          },
        }}
      >
        <Container maxWidth="sm" sx={{ MarginLeft: '1px' }}>
          <Grid
            container
            spacing={1}
            sx={{
              marginBottom: `${isMobile && location?.pathname !== '/booking' ? '110px' : '0'}`,
              padding: { xs: 0, sm: 4 },
            }}
          >
            {/* Logo Section */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                alignItems: 'center',
                marginRight: '1.5rem',

                '@media (max-width: 899px)': {
                  marginBottom: '2rem',
                marginRight: '0rem',

                },
              }}
            >
              <Box>
                <img
                  className="footerlogo"
                  src="/assets/images/Footer/logosmall.png"
                  alt="Logo"
                  style={{
                    height: '96px',
                    width: '156px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Grid>

            {/* Footer Items Section */}
            <Grid
              item
              xs={3}
              sm={6}
              md={4}
              sx={{
                marginRight: '-3rem',
                '@media (max-width: 650px)': {
                  marginRight: '-6rem',
                },
                '@media (max-width: 599px)': {
                  marginRight: '6rem',
                },
                '@media (max-width: 499px)': {
                  marginRight: '1rem',
                },
                textAlign: { xs: 'center', sm: 'center' },
              }}
            >
              <List
                dense
                sx={{
                  width: '3rem',
                  fontSize: { xs: 14, sm: 16 },
                  marginTop: '1.5rem',
                  '@media (max-width: 599px)': {
                    paddingRight: '1rem',
                  },
                }}
              >
                {footerItems?.map((item, key) => (
                  <Link
                    key={key}
                    to={item?.link}
                    style={{
                      textDecoration: 'none',
                      color: 'gray',
                    }}
                  >
                    <ListItem>
                      <ListItemText primary={item?.itemName} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>
            <Box
              sx={{
                display: 'none',
                '@media (max-width: 499px)': {
                  display: 'block',
                  height: '150px', 
                  width: '1px', 
                  backgroundColor: 'gray', 
                  border: 'none', 
                  margin: '50px 10px 0 0', 
                },
              }}
            />

            {/* Contact Section */}
            <Grid
              item
              xs={5}
              sm={3}
              md={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'left', sm: 'flex-start' },
                textAlign: { xs: 'left', sm: 'left' },
                '@media (max-width: 599px)': {
                  fontSize: '7px',
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: 18, sm: 20 },
                  fontWeight: 600,
                  color: colors.primary,
                  mb: 2,
                }}
              >
                Contact Us!
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'gray',
                  mb: 1,
                  width: '10rem',

                  '@media (max-width: 599px)': {
                    width: '9rem',
                  },
                }}
              >
                <LocalPhoneIcon sx={{ marginRight: 1, fontSize: { xs: 18, md: 19, lg: 22 } }} />
                <Typography
                  sx={{
                    width: 'auto',
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  +971 58 549 0727
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  color: 'gray',
                  mb: 1,
                  cursor: 'pointer',
                  '&:hover': { color: '#aeadad' },
                  '@media (max-width: 599px)': {
                    width: '16rem',
                  },
                }}
                onClick={openMap}
              >
                <PlaceIcon sx={{ marginRight: 1, fontSize: { xs: 18, md: 19, lg: 22 } }} />
                <Typography
                  sx={{
                    width: '20rem',
                    fontSize: { xs: 14, sm: 16 },
                    '@media (max-width: 599px)': {
                      width: '16rem',
                    },
                    '@media (max-width: 399px)': {
                      width: '13rem',
                    },
                  }}
                >
                  AL KHABAEESI BUILDING, PLOT NO. 285-0, Makani No. 3229195132, office no. 18-64, Dubai, UAE.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'gray',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                  '@media (max-width: 599px)': {
                    width: '4rem',
                  },
                }}
              >
                <a
                  href="http://www.instagram.com/zealcurtains"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginRight: 8,
                  }}
                >
                  <InstagramIcon sx={{ '&:hover': { color: '#a1a0a0' }, fontSize: { xs: 18, md: 19, lg: 22 } }} />
                </a>
                <a
                  href="http://www.facebook.com/zealcurtains"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginRight: 8,
                  }}
                >
                  <FacebookIcon sx={{ '&:hover': { color: '#a1a0a0' }, fontSize: { xs: 18, md: 19, lg: 22 } }} />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* ornge section */}
      {location?.pathname !== '/booking' && (
        <Grid
          className={`floating-card ${scrollValue >= 1350 ? 'active' : ''}`}
          sx={{
            backgroundColor: colors.primary,
            borderRadius: '5px 5px 0px 0px',
            padding: '12px',
            display: { sx: 'flex', sm: 'none' },
            position: 'fixed',
            bottom: '76px',
            width: '100%',
          }}
        >
          <Link to={'/booking'} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
              <IconButton sx={{ marginRight: 1 }}>
                <img src={`/assets/images/Header/icon.png`} alt=" 1" style={{ width: '16px', height: '18px' }} />
              </IconButton>
              <Typography className="click" variant="body1" sx={{ fontSize: '16px', fontWeight: '300' }}>
                Click here to {''}
                <Typography
                  className="book"
                  component="span"
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#fff', fontSize: '16px' }}
                >
                  Book A Free Home Visit
                </Typography>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              className="select"
              sx={{ color: '#fff', fontWeight: '200', fontSize: '12px', pl: 5.5 }}
            >
              Select your convenient time slot in the next step.
            </Typography>
          </Link>
        </Grid>
      )}
      {/* white section */}
      <Grid
        container
        spacing={1}
        sx={{
          padding: '1rem 1rem',
          display: { sx: 'flex', sm: 'none' },
          flexDirection: { sx: 'row' },
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'white',
        }}
      >
        {footerItemsMobile?.map((item, key) => (
          <Grid key={key} item xs={3} sm={6} md={3} lg={3}>
            <Link to={item?.pathName} style={{ textDecoration: 'none' }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column' }, alignItems: 'center' }}>
                {location?.pathname === item?.pathName ? (
                  <img src={item?.activeImage} alt={item?.alt} style={{ width: '16px', height: '18px' }} />
                ) : (
                  <img src={item?.inActiveImage} alt={item?.alt} style={{ width: '16px', height: '18px' }} />
                )}
                <Typography sx={{ textAlign: 'center', color: 'black', fontSize: '12px' }}>{item?.itemName}</Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: '1rem 0px ',
          '@media (max-width: 599px)': {
            p: location?.pathname === '/booking' ? '1rem 0px 5rem 0px ' : '0.8rem 0 11rem 0',
            // pb: '6rem',
            // pt: '1rem',
          },
        }}
      >
        <Typography sx={{ color: 'gray' }}>
          tech driven by{' '}
          <a href="https://osperb.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            {' '}
            <span style={{ color: '#007aff' }}>osperb</span>{' '}
          </a>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
